<template>
    <div id="entrance">
        <div>
            <div class="gift" v-if="isGiftAuto" @click="jump(1)">
                <img src="https://photo.zzliangzhu.top/activity_cover/jindianlibao.jpg"/>
                <h4>进店有礼</h4>
            </div>
            <div class="activity" v-for="(item, index) in activityList" :key="index" @click="jump(2, item.isNeedGroup, item.id)">
                <img :src="item.coverImg"/>
                <h4>{{item.activityName}}</h4>
            </div>
        </div>
        <h4 v-if="!isGiftAuto && !isRedCard" class="tips">活动预热中 敬请期待…</h4>
    </div>
</template>

<script>
import {getactivities} from '@/api/redCard';
import {score} from '@/api/eggs';
export default {
    data () {
        return {
            token: '', //shoptoken
            activityList:[], //活动列表
            isGiftAuto: false, //是否有进店有礼权限
            isRedCard: false, //是否有抽红包权限
            vertical: '', //是否为竖屏
        }
    },
    mounted () {
        const {token, vertical} = this.$route.query;
        this.token = token;
        vertical && (this.vertical = vertical);
        this.giftAuto();
        this.activities();
    },
    methods: {
        /* 查询进店有礼权限 */
        async giftAuto () {
            const {token, $toast} = this;
            let res = await score({token});
            const {status, data, message} = res;
            status == 0 ? 
            (data.score > 0 && (this.isGiftAuto = true)) :
            this.isGiftAuto = false;
        },

        /* 获取活动列表 */
        async activities () {
            const {token, $toast} = this;
            let res = await getactivities({token});
            const {status, data, message} = res;
            status == 0 ?
            this.activityList = data.data :
            this.isRedCard = false;
        },

        /* 跳转 */
        jump (status, isNeedGroup, id) {
            const {token, $router, vertical} = this;
            let arr = {name:'', query:{token, vertical, id}};
            switch (status) {
                case 1:
                    arr.name = 'eggs';
                    break;
                case 2: 
                    if (isNeedGroup) {
                        arr.name = 'redCardIndex';
                    } else {
                        arr.name = 'luckDraw';
                        arr.query.id = this.activityList[0].groups[0].Id;
                    }
            }
            $router.push(arr);
        }
    }
}
</script>

<style lang="less" src="@/style/entrance.less">

</style>