import axios from '../libs/gadgetsAjaxRequest';


/* 获取活动列表 */
const getactivities = (data) => {
    return axios.request({
        url: 'api/yun/getactivities',
        method: 'post',
        data
    });
};

/* 获取指定礼品分组的礼品清单 */
const getgroupgift = (data) => {
    return axios.request({
        url: 'api/yun/getgroupgift',
        method: 'post',
        data
    });
};

/* 获取营销活动礼品的二维码 */
const getgiftqrcode = (data) => {
    return axios.request({
        url: 'api/yun/getgiftqrcode',
        method: 'post',
        data
    });
};

export {
    getactivities,
    getgroupgift,
    getgiftqrcode
}